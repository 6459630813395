<div class="modal-content">

    <div class="modal-body">
        
        <div class="card p-4 promo"><br>
            
            <div class="card-body" id="promo" style="text-align: center; color: black !important; font-size: 16px !important;">
                 
                <img width="80%"  src="../../assets/ride-events.png"><br><Br>
            </div>
            <div class="card-body" id="promo" style="text-align: left; color: black !important; font-size: 16px !important;">
            <a  href="https://salute2kindness.com/" target="_blank" style="color: #ff0000 !important;text-decoration: underline; font-size: 17px !important; font-weight: 400;">Salute2Kindness Awards</a> - November 5th Vallejo CA @ The Empress Theater  <br><br>

            The S2K Awards Gala acknowledges individuals, organizations, and Veterans for their acts of kindness with awards. S2K will also be introducing 
            the events and festivities of the World Kindness Week kindness and #1Voice4Kindness.<br><br>
            
            <a href="https://theworldkindnessusa.org/" target="_blank"  style="color: #ff0000 !important;text-decoration: underline;  font-size: 17px !important; font-weight: 400;">The World Kindness USA</a> - November 12th <br>
            This is the 25th year anniversary for World Kindness and they are holding an award ceremony and celebration on Laguna Beach. (Laguna Beach CA)<br><br>
        

            <a href="https://www.rock4kindness.com/" target="_blank" style="color: #ff0000 !important;text-decoration: underline;  font-size: 17px !important; font-weight: 400;"> Rock4Kindness </a> - November 12th      <br>
            There will be a celebrity event filled with performances and a online panel discussing several topics. 
            The Ride4Kindenss Cyclist will arrive for the final leg of the cross-country ride. <br><br>
            
            <a href="https://www.danceforkindness.com/" target="_blank" style="color: #ff0000 !important;text-decoration: underline;  font-size: 17px !important; font-weight: 400;">Dance4kindness</a> - November 13th   <Br>
            Dance4Kindness has flash mobs doing a kindness dance all over the world at the same time on this final day of the World Kindness Week<br><br>
            
            <label style="color: #ff0000 !important;text-decoration: underline;  font-size: 17px !important; font-weight: 400;">Fashion4Kindness</label> - November 10th<Br>
            Fashion Designer Liden Moore will be hosting a fashion exhibit downtown LA highlighting Fashion4Kindness<br><BR>
            </div>
            <div class="card-body" id="promo" style="text-align: center; color: black !important; font-size: 16px !important;">
            <button id="cancel-btn" class="btn cancel-btn px-4"  (click)="onCancel()">Close</button>   

            </div>

</div>
</div>

</div>