import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/Modal';

@Component({
  selector: 'app-termscondition',
  templateUrl: './termscondition.component.html',
  styleUrls: ['./termscondition.component.scss']
})
export class TermsconditionComponent implements OnInit {

  constructor(private modalRef: BsModalRef, ) { }

  ngOnInit(): void {
  }

  public onCancel(): void {
    this.modalRef.hide();
  }

}
