import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ridekinditinerary',
  templateUrl: './ridekinditinerary.component.html',
  styleUrls: ['./ridekinditinerary.component.scss']
})
export class RidekinditineraryComponent implements OnInit {

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  
  public onCancel(): void {
    this.modalRef.hide();
  }

}
