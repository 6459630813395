import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[inputNumeric]'
})
export class NumericDirective {

    @Input('numericType') numericType: string; // number | decimal
    @Input('allowNegative') allowNegative: boolean;
    @Input('decimalPointCount') decimalPointCount: number;

    private specialKeys = {
        number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Shift', 'Alt', 'Control', 'Delete'],
        decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Shift', 'Alt', 'Control', 'Delete'],
    };

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(keyboardEvent: KeyboardEvent) {
        const regex = {
            number: this.allowNegative ? new RegExp(/^[-]?\d*$/) : new RegExp(/^\d*$/),
            decimal: this.allowNegative ? new RegExp(/^[-]?\d*(\.\d*)?$/g) : new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
        };

        if (this.specialKeys[this.numericType].indexOf(keyboardEvent.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        // @ts-ignore
        const target: HTMLInputElement = keyboardEvent.target;
        const previousInputValue = target.value;
        const enterIndexBeforeValue = previousInputValue.slice(0, target.selectionStart);
        const enterIndexAfterValue = previousInputValue.slice(target.selectionEnd, previousInputValue.length);
        const enterInputValue = `${enterIndexBeforeValue}${keyboardEvent.key}${enterIndexAfterValue}`;

        if (enterInputValue && !String(enterInputValue).match(regex[this.numericType])) {
            event.preventDefault();
        }

        // find better regex now I don't want to break old code
        if (this.numericType === 'decimal' && this.decimalPointCount) {
            if (enterInputValue && enterInputValue.toString().split('.').length === 2) {
                if (enterInputValue.toString().split('.')[1].length > this.decimalPointCount) {
                    event.preventDefault();
                }
            }
        }
    }
}
