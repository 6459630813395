import { Component, OnInit } from '@angular/core';
import { StorageService } from '../shared/services/storage.service';
import { AppStorage, MessageFor } from '../providers/enum';
@Component({
  selector: 'app-chanall-home',
  templateUrl: './chanall-home.component.html',
  styleUrls: ['./chanall-home.component.scss']
})
export class ChanallHomeComponent implements OnInit {

  public showLoading: boolean = true;
  public chanDetails: any;
  public slideShowArray: any;
  public clientArray: any;
  public activeSwigitArray: any;
  public activeBundlesArray: any;
  public featuredSwigitArray: any;
  
  constructor(
    private storageService: StorageService
  ) { 
   // alert("eee");
    this.loadScript('../../assets/js/popper.min.js');
    this.loadScript('../../assets/js/bootstrap.min.js');
    this.loadScript('../../assets/js/owl.carousel.min.js');
    this.loadScript('../../assets/js/slick.min.js');
    this.loadScript('../../assets/js/slick-animation.min.js');
    this.loadScript('../../assets/js/jquery.magnific-popup.min.js');
    this.loadScript('../../assets/js/swiper.min.js');
    this.loadScript('../../assets/js/swiper-custom.js');
    this.loadScript('../../assets/js/bootstrap-datepicker.min.js');
    this.loadScript('../../assets/js/sidebar.js');
    this.loadScript('../../assets/js/main.js');

 
  }

  ngOnInit(): void {
    let chanDetailsIn = this.storageService.get(AppStorage.chanallDetails, true);
    
    this.chanDetails = JSON.parse(chanDetailsIn);
    this.slideShowArray = this.chanDetails.app.slideShowStreams;
    this.clientArray = this.chanDetails.app.activeClients;
    this.activeSwigitArray = this.chanDetails.app.activeStreams;
    this.activeBundlesArray = this.chanDetails.app.activeBundles;
    this.featuredSwigitArray = this.chanDetails.app.featuredStreams;
    //console.log("details on chanall", this.slideShowArray);
  }

  public loadScript(url: string) {
    
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
   // alert("hello");
  }
}
