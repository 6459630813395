import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

//import { Component, TemplateRef, OnInit } from '@angular/core';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { LoginComponent } from '../login/login.component'
import { RegisterComponent } from '../register/register.component';
import { PromovideoComponent } from '../promovideo/promovideo.component';
import { GuestticketComponent } from '../guestticket/guestticket.component';
import { TermsconditionComponent } from '../termscondition/termscondition.component';
import { StripeformComponent } from '../stripeform/stripeform.component';
import { ContactusComponent } from '../contactus/contactus.component';
import { StorageService } from '../shared/services/storage.service';
import { AppStorage } from '../providers/enum';
import { LoginService } from '../login/login.service';
import { WatchwithticketComponent } from '../watchwithticket/watchwithticket.component';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {

 
  public modalRef: BsModalRef;
  public userData: any;

  constructor(private modalService: BsModalService,
    private storageService: StorageService,
    private loginService: LoginService) { }


  ngOnInit(): void {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    //console.log('this.userData', this.userData);
  }

  public openLoginModal(): void {
    this.modalRef = this.modalService.show(
      LoginComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }

  public openRegisterModal(): void {
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }

  public onPromoClick(): void {
    this.modalRef = this.modalService.show(
      PromovideoComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray fullWidth modal-lg' })
    );

  }


  public onDonatePerViewClick(): void {
   // if (this.userData && this.userData.userCode) {
      this.modalRef = this.modalService.show(
        GuestticketComponent,
        Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
      );
   /* } else {
      this.openLoginModal();
    }*/
  }

  public onWatchWithTCClick(): void {
    this.modalRef = this.modalService.show(
      WatchwithticketComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-lg' })
    );
  }

  public onTermsClick(): void {
    this.modalRef = this.modalService.show(
      TermsconditionComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-lg' })
    );
  }

  public openContactUsModal(): void {
    this.modalRef = this.modalService.show(
     
      ContactusComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-lg' })
    );
  }


  public onLogout(): void {
    this.loginService.onLogout();
    window.location.reload();
  }
}
