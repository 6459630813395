<div class="modal-content">

    <div class="modal-body">
        <div class="card p-4 login">
            <div class="card-body p-0" id="login">
                <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
                <h1 class="loginLabel">My profile</h1>
                <br>
                <form [formGroup]="myProfileFormGroup" autocomplete="off">
                    <div class="form-group">
                        <label class="form-label mb-1 loginLabel" for="inputUserName">Name</label>
                        <input type="text" class="form-control" id="inputUserName" formControlName="name" disabled>
                    </div>

                    <div class="form-group">
                        <label class="form-label mb-1 loginLabel" for="inputUserName">Username</label>
                        <input type="text" class="form-control" id="inputUserName" formControlName="userName" disabled>
                    </div>

                    <div class="form-group">
                        <label class="form-label mb-1 loginLabel" for="inputUserName">Email</label>
                        <input type="text" class="form-control" id="inputUserName" formControlName="email" disabled>
                    </div>

                    <div class="text-center">
                        <p class="createAccount" (click)="onChangePasswordClick()">
                            Change Password
                        </p>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>