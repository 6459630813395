import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { FetchJsonService } from '../../shared/services/fetch-json.service';
import { AuthService, AuthResponse } from '../services/auth.service';
import { AppStorage, MessageFor } from '../../providers/enum';
import { LoginAction } from '../../providers/constant';
import { StorageService } from '../../shared/services/storage.service';
import { DonatePerViewService } from '../../donate-per-view-modal/services/donate-per-view.service';

@Component({
  selector: 'app-resendcode-route',
  templateUrl: './resendcode-route.component.html',
  styleUrls: ['./resendcode-route.component.scss']
})
export class ResendcodeRouteComponent implements OnInit {

  //#region variables

  public loginMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public loginFormGroup: FormGroup;
  public streamDetails;
  public domain: string;
  public title: string;
  public myAngularxQrCode: string = null;
  public userData: any;
  private subs = new SubSink();
  public streamCorrect = true;
  public streamDetailsFull: any;
  //#endregion

  //#region life-cycle hook

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private fetchJsonService: FetchJsonService,
    private storageService: StorageService,
    private donatePerViewService: DonatePerViewService,
    private loginService: AuthService) { }

  ngOnInit(): void {

    
    this.showLoading = true;
    //alert("sdf");
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    this.streamCorrect = true;
   // console.log(this.userData);
    if(this.userData != "")
    {
      try {
        let details = this.storageService.get(AppStorage.streamDetails, true);
        this.streamDetails = JSON.parse(details);
        this.streamDetailsFull = this.streamDetails[0];

      var formDataC = new FormData();
      //console.log("str = ", this.streamDetailsFull);
      formDataC.append("userCode", this.userData.userCode);
      formDataC.append("menuGuid", this.streamDetailsFull.menu_guid);
      formDataC.append("streamGuid", this.streamDetailsFull.stream_guid);
      formDataC.append("appId", this.streamDetailsFull.app_code);

      this.subs.sink = this.donatePerViewService.sendTicketCode(formDataC)
      .subscribe(
        async (response: any) => {
          //console.log('response: ', response);
          this.errorMessage = "Your Ticketcode has been sent on your registered email address.";
          this.showLoading = false;
          if (response.status) {
            this.showLoading = false;
            this.errorMessage = "Your Ticketcode has been sent on your registered email address.";
          }});


        } catch (error) {
          //console.log('error: ', error);
          this.showLoading = false;
          //  this.streamCorrect = false;
          this.errorMessage = "No user is logged in, please Login first to get the Ticketcode.";
        }
    }
    else
    {
      this.errorMessage = "No user is logged in, please Login first to get the Ticketcode.";

    }
    //console.log("err=",this.errorMessage);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion



  //#endregion

  //#region public methods






  //#endregion

}
