import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APP_URLS } from '../../providers/constant';

@Injectable({
  providedIn: 'root'
})
export class GuestticketService {

  private paymentApiUrl = `${APP_URLS.WEB_API_URL}`;

  constructor(private http: HttpClient) { }

  public dpvPayment(formData: any): Observable<any> {
    return this.http.post<any>(`${this.paymentApiUrl}/manageticketcode`, formData);
  }
  public dpvTicketGen(formData: any): Observable<any> {
    return this.http.post<any>(`${this.paymentApiUrl}/manageticketcode`, formData);
  }
  public dpvPayforGiftTicket(formData: any): Observable<any> {
    return this.http.post<any>(`${this.paymentApiUrl}/sendguestticketrequests`, formData);
  }
}
