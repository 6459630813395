import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { VimeoplayerComponent } from '../vimeoplayer/vimeoplayer.component';

@Component({
  selector: 'app-howtowatchontv',
  templateUrl: './howtowatchontv.component.html',
  styleUrls: ['./howtowatchontv.component.scss']
})
export class HowtowatchontvComponent implements OnInit {

  public DomainName: string;
  public streamURL: any;
  public strURL: string;

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions) { }

  ngOnInit(): void {
    this.streamURL = this.options.initialState;
    this.DomainName = document.location.host;
    this.strURL = this.streamURL.url;
  }

  public onCancel(): void {
    //alert("df");
    this.modalRef.hide();
  }

  public watchPromo(videourl: string): void {
    
    this.modalRef.hide();
    let initialState = { url: videourl };
    this.modalRef = this.modalService.show(
      VimeoplayerComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
    );

  }

}
