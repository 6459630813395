/** App Storage used for get stored application Local Storage */
export enum AppStorage {
  data = 'data',
  isLiveEventBuyed = 'isLiveEventBuyed',
  streamDetails = 'streamDetails',
  siteDomain = 'siteDomain',
  siteTitle = 'siteTitle',
  streamPlayUrl = 'streamPlayUrl',
  lastVisitedRoute = 'lastVisitedRoute',
  chanallDetails = 'chanallDetails',
  clientDetails = 'clientDetails',
  appDetails1 = "appDetails1",
  appDetailsStorage = "appDetailsStorage",
  appStripeData = "appStripeData",
  appCodeLS = "appCodeLS",
  bundleDetails = "bundleDetails",
  historyBundle = "historyBundle",
  swigitPurchased = "swigitPurchased",
  appBundleStripeData = "appBundleStripeData"
}

/** Message used for get form name */
export enum MessageFor {
    LoginForm = 'loginForm',
    RegisterForm = 'registerForm',
    AccountActivationFrom = 'accountActivationFrom',
    DonatePerViewModal = 'donatePerViewModal',
    ChangePasswordForm = 'changePasswordForm',
    ForgotPasswordForm = 'forgotPasswordForm',
    TicketCodeForm = 'ticketCodeForm',
    SupportForm = 'supportForm',
    MyProfileForm = 'myProfileForm'
}

export enum PaymentType {
    Donation = 'D',
    Fixed = 'F'
}

export enum StreamType {
    Multiple = 'M',
    Single = 'S'
}

export enum PaymentMode {
    Test = 'T',
    Live = 'L'
}

export enum PaymentString {
    StripeMsg = "SWIGIT Access Code Purchase - ",
}