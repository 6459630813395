import { Component, OnInit } from '@angular/core';
import { StorageService } from '../shared/services/storage.service';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStorage, MessageFor } from '../providers/enum';
@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss']
})
export class SuccessMessageComponent implements OnInit {

  public DomainName: string;
  public streamDetails;

  constructor(private modalRef: BsModalRef,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.DomainName = document.location.host;
  }

  
  public onCancel(): void {
    
    this.modalRef.hide();
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);

    let serverURLn ="";
    let urlopen = "";
            if(this.streamDetails[0].stream_payment_options.local_server_flag == 'Y')
            {
              serverURLn = this.streamDetails[0].stream_payment_options.local_server_url;
              urlopen = serverURLn ;
            }
            else
            {
              
              serverURLn = this.streamDetails[0].stream_payment_options.live_server_url;
               urlopen = serverURLn ;
            }  
          
            
            window.open(urlopen, "_self");
  }

}
