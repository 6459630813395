<div class="modal-content">
  <div class="modal-body">
    <div class="card p-4 login">
      <div class="card-body" id="login">
        <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
        <h1 *ngIf="!paymentSuccess" class="loginLabel">Proceed with your Payment.. Thank You! </h1>

        <div class="form-group">

          <form [formGroup]="payFormGroup" autocomplete="off">
            <div class="form-group" *ngIf="!paymentSuccess">
              <label class="form-label mb-1 loginLabelSml" for="inputUserName">Email Address <span
                  class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" #myInput type="text" class="form-control" id="z"
                placeholder="Email Address" formControlName="inputEmail"
                [ngClass]="{ 'is-invalid': isCheckValidation && payControls.inputEmail.errors }"
                (click)="emailChg($event.target.value)" (input)="emailChg($event.target.value)">
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputEmail.errors) ||
                  (!!payControls?.inputEmail.touched && !!payControls?.inputEmail.invalid)">
                <ng-container class="pt-2" *ngIf="!!payControls?.inputEmail.errors.required">
                  {{ payMessage.inputEmail.errors.required }}
                </ng-container>
              </small>
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputEmail.errors) ||
                  (!!payControls?.inputEmail.touched && !!payControls?.inputEmail.invalid)">
                <ng-container class="pt-2" *ngIf="payControls?.inputEmail.errors.checkEmail">
                  {{ payMessage.inputEmail.errors.invalid }}
                </ng-container>
              </small>
            </div>

            <div class="form-group" *ngIf="!paymentSuccess">
              <label class="form-label mb-1 loginLabelSml" for="inputName">Name <span
                  class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" type="text" class="form-control" id="inputName"
                placeholder="Full Name" formControlName="inputName"
                [ngClass]="{ 'is-invalid': isCheckValidation && payControls.inputName.errors }"
                (click)="emailChg($event.target.value)" (input)="emailChg($event.target.value)">
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputName.errors) ||
                  (!!payControls?.inputName.touched && !!payControls?.inputName.invalid)">
                <ng-container class="pt-2" *ngIf="!!payControls?.inputName.errors.required">
                  {{ payMessage.inputName.errors.required }}
                </ng-container>
              </small>
            </div>

            <div class="form-group" *ngIf="!paymentSuccess && streamType === StreamType.Multiple">
              <label class="form-label mb-1 loginLabelSml" for="inputName">Event Date <span
                class="required-field">*</span></label>
              <ng-select id="ddlModel" class="ng-select-capitalize mb-2" [items]="streamMultipleDates" bindValue="stream_date_PK"
                bindLabel="eventStDateTime" style="min-width: 150px;" [multiple]="false" [closeOnSelect]="true"
                [searchable]="false" [clearable]="false" placeholder="Select Event Dates" formControlName="selectedDates">
              </ng-select>
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.selectedDates.errors) ||
                (!!payControls?.selectedDates.touched && !!payControls?.selectedDates.invalid)">
                <ng-container class="pt-2" *ngIf="!!payControls?.selectedDates.errors.required">
                  {{ payMessage.selectedDates.errors.required }}
                </ng-container>
              </small>
            </div>

            <div *ngIf="!paymentSuccess" class="col-md-12 form-group">

              <div *ngIf="paymentType === PaymentType.Donation">
                <h1 class="loginLabel form-label mb-1">Donation Amount.. </h1>
                <div style="display: flex; justify-content: space-around;">
                  <div *ngFor="let option of donationAmount">
                    <input type="radio" name="radioAmount" class="radioClass" value="option.value"
                      [checked]="option.value === minimumAmount" (click)="setRadioButton(option.value)"> <span
                      class="radioSpan">${{ option.value }}</span>
                  </div>
                </div>
                <div class="col-md-12 form-group" style="text-align: center;">
                  <input type="radio" name="radioAmount" id="radOtherAmount" value="0" class="radioClass"
                    (click)="setRadioButton('0')"> <span class="radioSpan" >{{customAmountText}} </span>

                  <input *ngIf="isOtherOptionPaymentSelected" inputNumeric numericType="number" type="text"
                    class="form-control mt-2" id="txtotherAmount" placeholder="Enter Amount"
                    (input)="onChnageInput($event.target.value)" style="border: 1px solid rgb(107, 107, 107);"
                    (click)="onChnageInput($event.target.value)">
                </div>
              </div>
              <!-- <input type="radio" name="radioAmount" class="radioClass" value="5" (click)="setRadioButton('50')">
              <span class="radioSpan">$50</span>
              <input type="radio" name="radioAmount" class="radioClass" value="10" (click)="setRadioButton('100')">
              <span class="radioSpan">$100</span>
              <input type="radio" name="radioAmount" class="radioClass" value="20" (click)="setRadioButton('250')">
              <span class="radioSpan">$250</span> -->
              <div class="col-md-12 form-group" style="text-align: center;">
                <div style="height: 75px !important;overflow: hidden;" id="divPayment" *ngIf="errorMessage === ''">
                  <button (click)="finalpay()" class="btn btn-info btn-block mt-4">Pay With Credit Card</button><br>
                  <!-- <ngx-paypal  [config]="payPalConfig" ></ngx-paypal> -->
                </div>
              </div>
            </div>
            <!-- <div *ngIf="!paymentSuccess">&nbsp;</div> -->
            <div class="text-center">
              <p id="error-message">
                {{ errorMessage }}
              </p>
            </div>
            <!-- <div class="col-md-12">

            </div> -->
          </form>
         
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner *ngIf="showLoading">
</app-spinner>