import { Component, OnInit } from '@angular/core';
import { StorageService } from '../shared/services/storage.service';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppStorage, MessageFor } from '../providers/enum';

@Component({
  selector: 'app-watchwithticketcode',
  templateUrl: './watchwithticketcode.component.html',
  styleUrls: ['./watchwithticketcode.component.scss']
})
export class WatchwithticketcodeComponent implements OnInit {

  public DomainName: string;
  public streamDetails;

  constructor(private modalRef: BsModalRef,
    private storageService: StorageService) { }

  ngOnInit(): void {
  }

  
  public onCancel(): void {
    
    this.modalRef.hide();
    
  }

}
