import { Injectable } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    ValidatorFn,
    AbstractControl,
    ValidationErrors
} from '@angular/forms';
import { ValidatorParms } from '../models/common.model';
import * as moment from 'moment';
import { IP_ADDRESS_REGEX } from '../../providers/constant';
@Injectable({
    providedIn: 'root'
})
export class CommonUtilsService {

    constructor() { }

    public static mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    public static checkValidMac(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.value) {
                if (/^[a-fA-F0-9]{12}$/.test(control.value.replace(/\s/g, '').replace(/:/g, '').replace(/-/g, ''))) {
                    control.setErrors(null);
                } else {
                    control.setErrors({ checkValidMac: true });
                }
            }
        };
    }

    public static checkValidIp(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const ipAddress = control.value;
            if (ipAddress && ipAddress.trim()) {
                if (!IP_ADDRESS_REGEX.test(ipAddress)) {
                    control.setErrors({ checkValidMac: true });
                } else {
                    control.setErrors(null);
                }
            }
        };
    }

    public static checkEmail(controlName: string, isMultiple: boolean) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            // const regEmail = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
            //const regEmail2 = /(^((?>[a-zA-Z\d!#$%&'*+\-/=?^_`{|}~]+\x20*|"((?=[\x01-\x7f])[^"\\]|\\[\x01-\x7f])*"\x20*)*(?<angle><))?((?!\.)(?>\.?[a-zA-Z\d!#$%&'*+\-/=?^_`{|}~]+)+|"((?=[\x01-\x7f])[^"\\]|\\[\x01-\x7f])*")@(((?!-)[a-zA-Z\d\-]+(?<!-)\.)+[a-zA-Z]{2,}|\[(((?(?<!\[)\.)(25[0-5]|2[0-4]\d|[01]?\d?\d)){4}|[a-zA-Z\d\-]*[a-zA-Z\d]:((?=[\x01-\x7f])[^\\\[\]]|\\[\x01-\x7f])+)\])(?(angle)>)$)/i;
            if (control.value) {
                if (isMultiple) {
                    const emailArray = control.value.split(',');
                    emailArray.forEach(element => {
                        if (element.match(regEmail)) {
                            control.setErrors(null);
                        } else {
                            control.setErrors({ checkEmail: true });
                        }
                    });
                } else {
                    if (control.value.match(regEmail)) {
                        control.setErrors(null);
                    } else {
                        control.setErrors({ checkEmail: true });
                    }
                }
            }
        };
    }

    public static minOrMaxValueRequired(minControlName: string, maxControlName: string): any {
        return (formGroup: FormGroup) => {
            const minControl = formGroup.controls[minControlName];
            const maxControl = formGroup.controls[maxControlName];
            const minControlValue = +minControl.value;
            const maxControlValue = +maxControl.value;

            if ((minControlValue && minControlValue >= 0) || (maxControlValue && maxControlValue >= 0)) {
                if (!(minControl.errors && minControl.errors.minValueError)) {
                    minControl.setErrors(null);
                }
                if (!(maxControl.errors && maxControl.errors.maxValueError)) {
                    maxControl.setErrors(null);
                }
                return;
            }
            // set error on matchingControl if validation fails
            minControl.setErrors({ minOrMaxValueRequired: true });
            maxControl.setErrors({ minOrMaxValueRequired: true });
        };
    }

    public static checkMinValue(minControl: FormControl, maxControl: FormControl) {
        const minControlValue = +minControl.value;
        const maxControlValue = +maxControl.value;

        if (minControlValue !== 0 && !maxControlValue) {
            return;
        }

        maxControl.setErrors(null);

        return minControlValue > maxControlValue ? { minValueError: true } : null;
    }

    public static checkMaxValue(maxControl: FormControl, minControl: FormControl) {
        const minControlValue = +minControl.value;
        const maxControlValue = +maxControl.value;

        if (!maxControlValue || (maxControlValue !== 0 && !minControlValue)) {
            return;
        }

        minControl.setErrors(null);

        return minControlValue > maxControlValue ? { maxValueError: true } : null;
    }

    public static noWhitespaceValidator(control: FormControl) {
        const isWhitespace = control.value ? (control.value || '').trim().length === 0 : null;
        const isValid = !isWhitespace;
        return isValid ? null : { required: true };
    }

    public static phoneNumberValidator(control: FormControl) {
        const phoneNumberValue = control.value;
        if (phoneNumberValue) {
            return (/^\d{7,}$/).test(phoneNumberValue.replace(/[+\.]|ext/gi, '')) ? null : { phoneInvalid: true };
        }
        return null;
    }

    public static passwordValidator(control: FormControl) {
        const passwordValue = control.value;
        if (passwordValue) {
            return (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/).test(passwordValue) ? null : { passwordInvalid: true };
        }
        return null;
    }

    public static urlValidator(control: FormControl) {
        const urlValue = control.value;
        if (urlValue) {
            // tslint:disable-next-line:max-line-length
            const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            return regexp.test(urlValue) ? null : { urlInvalid: true };
        }
        return null;
    }

    public static emailValidator(control: FormControl) {
        const emailValue = control.value;
        if (emailValue) {
            // tslint:disable-next-line:max-line-length
            const regexp = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
            return regexp.test(emailValue) ? null : { emailInvalid: true };
        }
        return null;
    }

    public static maxNumberValidator(maxNumberValue: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const controlValue = +control.value;
            if (controlValue && controlValue > maxNumberValue) {
                return { maxNumberInvalid: true };
            }
            return null;
        };
    }

    public static minNumberValidator(minNumberValue: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const controlValue = +control.value;
            if (((controlValue || controlValue === 0) && controlValue < minNumberValue)) {
                return { minNumberInvalid: true };
            }
            return null;
        };
    }

    public static dataURItoBlob(dataURI: any) {
        let byteString: any;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    public static makeQueryStringFromJson(obj) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') {
                const childObject = this.makeQueryStringFromJson(obj[key]);
                if (childObject === undefined) {
                    delete obj[key];
                }
            } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        });
        const requestJson = Object.keys(obj).length > 0 || obj instanceof Array ? obj : undefined;
        if (requestJson) {
            const urlPrms = new URLSearchParams();
            for (const key in requestJson) {
                if (requestJson.hasOwnProperty(key)) {
                    urlPrms.set(key, requestJson[key]);
                }
            }
            return urlPrms.toString();
        } else {
            return undefined;
        }
    }

    public static alphaNumeric(c: FormControl) {
        if (c.value) {
            const regEx = new RegExp(/^[a-zA-Z0-9]*$/);
            if (!regEx.test(c.value)) {
                return { alphanumeric: true };
            }
        }
        return null;
    }

    public static updateFormControlValidaton(fromGroup: FormGroup, controlArray: ValidatorParms[]) {
        for (const arrObj of controlArray) {
            if (arrObj.required) {
                if (arrObj.Validator) {
                    fromGroup.controls[arrObj.control].setValidators([Validators.required, arrObj.Validator]);
                } else {
                    fromGroup.controls[arrObj.control].setValidators([Validators.required]);
                }
            } else {
                fromGroup.controls[arrObj.control].clearValidators();
            }
            fromGroup.controls[arrObj.control].updateValueAndValidity();
        }
    }

    public static localDateToUTC(localDate: Date): Date {
        const utcDate = moment.utc(localDate).toDate();
        return new Date(utcDate);
    }

    public static maxMinuteValueValidator(control: FormControl) {
        const isGrater = (control.value && control.value > 60) ? true : false;
        const isValid = !isGrater;
        return isValid ? null : { required: true };
    }

    public static maxHourValueValidator(control: FormControl) {
        const isGrater = (control.value && control.value > 24) ? true : false;
        const isValid = !isGrater;
        return isValid ? null : { required: true };
    }
}
