import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-donate-per-view-modal',
  templateUrl: './watchwithticket.component.html',
  styleUrls: ['./watchwithticket.component.scss']
})
export class WatchwithticketComponent implements OnInit {

  public DomainName: string;
  public streamURL: any;
  public strURL: string;

  constructor(private modalRef: BsModalRef,
    public options: ModalOptions) { 

      
    }

  ngOnInit(): void {

    this.streamURL = this.options.initialState;
    this.DomainName = document.location.host;
    this.strURL = this.streamURL.url;
    //alert(this.strURL);
  }

  public onCancel(): void {
    this.modalRef.hide();
  }

}

