import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GuestComponent } from './guest/guest.component';

import { AuthComponent } from './auth/auth.component';
import { LoginRouteComponent } from './auth/login-route/login-route.component';
import { LogoutRouteComponent } from './auth/logout-route/logout-route.component';
import { ResendcodeRouteComponent } from './auth/resendcode-route/resendcode-route.component';
import { RegisterRouteComponent } from './auth/register-route/register-route.component';
import { ChangePasswordRouteComponent } from './auth/change-password-route/change-password-route.component';
import { ForgotPasswordRouteComponent } from './auth/forgot-password-route/forgot-password-route.component';
import { WatchwithticketComponent } from './watchwithticket/watchwithticket.component';
import { AddthisComponent } from './addthis/addthis.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginRouteComponent,
      },
      {
        path: 'login/:apptitle',
        component: LoginRouteComponent,
      },
      {
        path: 'logout/:apptitle',
        component: LogoutRouteComponent,
      },
      {
        path: 'register/:apptitle',
        component: RegisterRouteComponent,
      },
      {
        path: 'resendcode/:apptitle',
        component: ResendcodeRouteComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordRouteComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordRouteComponent,
      },
    ],
  }, {
    path: 'addthis',
    component: AddthisComponent,
  },{
    path: '',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: 'watchwithticket', component: WatchwithticketComponent },
  // { path: 'login', component: LoginDirectComponent },
  { path: '**', component: GuestComponent }



  // { 
  //   path: '**',
  //   redirectTo: 'auth/login'
  // },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
