<div class="modal-content">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Terms and Conditions</h4>
        <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="terms-content">


            <p>Last
                Updated: November 9, 2019</p>



            <p>Please
                read these Terms of Service (the “Agreement”) carefully. Your use of the Site
                (as defined below) constitutes your consent to this Agreement.</p>



            <p>This
                Agreement is between you and Galapagos PEX (“Company” or “we” or “us”)
                concerning your use of (including any access to) our site currently located at purspek.com
                (together with any materials and services available therein, and successor
                site(s) thereto, the “Site”). This Agreement hereby incorporates by this
                reference any additional terms and conditions posted by Company through the
                Site or otherwise made available to you by Company.</p>



            <p>By using
                the Site, you affirm that you are of legal age to enter into this Agreement or,
                if you are not, that you have obtained parental or guardian consent to enter
                into this Agreement.</p>



            <p><strong>1.
                    Changes.</strong> We may change this Agreement from time to time by notifying you
                of such changes by any reasonable means, including by posting a revised
                Agreement through the Site. Any such changes will not apply to any dispute
                between you and us arising prior to the date on which we posted the revised
                Agreement incorporating such changes, or otherwise notified you of such
                changes.</p>



            <p>Your use of
                the Site following any changes to this Agreement will constitute your
                acceptance of such changes. The “Last Updated” legend above indicates when this
                Agreement was last changed. We may, at any time and without liability, modify
                or discontinue all or part of the Site (including access to the Site via any
                third-party links); charge, modify or waive any fees required to use the Site;
                or offer opportunities to some or all Site users.</p>



            <p><strong>2.
                    Information Submitted Through the Site</strong>. Your submission of
                information through the Site is governed by our Privacy Policy, located
                at&nbsp;<a href="http://www.purspek.com/privacy-policy">www.purspek.com/privacy-policy</a>. You
                represent and warrant that any information you
                provide in connection with the Site is and will remain accurate and complete
                and that you will maintain and update such information as needed.</p>



            <p><strong>3.
                    Jurisdictional Issues.</strong> The Site is controlled and operated from the United States and is
                not intended to subject Company to any non-U.S. jurisdiction or law. The Site
                may not be appropriate or available for use in some non-U.S. jurisdictions. Any
                use of the Site is at your own risk, and you must comply with all applicable
                laws, rules, and regulations in doing so. We may limit the Site’s availability
                at any time, in whole or in part, to any person, geographic area or
                jurisdiction that we choose.</p>



            <p><strong>4. Rules
                    of Conduct.</strong> In connection with the Site, you must not:</p>



            <p>Post,
                transmit or otherwise make available through or in connection with the Site any
                materials that are or may be: (a) threatening, harassing, degrading, hateful,
                or intimidating, or otherwise fail to respect the rights and dignity of others;
                (b) defamatory, libelous, fraudulent, or otherwise tortious; (c) obscene,
                indecent, pornographic, or otherwise objectionable; or (d) protected by
                copyright, trademark, trade secret, right of publicity, or privacy or any other
                proprietary right, without the express prior written consent of the applicable
                owner.</p>



            <p>Post,
                transmit, or otherwise make available through or in connection with the Site
                any virus, worm, Trojan horse, Easter egg, time bomb, spyware, or other
                computer code, file, or program that is or is potentially harmful or invasive
                or intended to damage or hijack the operation of, or to monitor the use of, any
                hardware, software, or equipment.</p>



            <p>Use the
                Site for any commercial purpose, or for any purpose that is fraudulent or
                otherwise tortious or unlawful.</p>



            <p>Use the
                Site for any commercial purpose, or for any purpose that is fraudulent or
                otherwise tortious or unlawful.</p>



            <p>Harvest
                or collect information about users of the Site.</p>



            <p>Interfere
                with or disrupt the operation of the Site or the servers or networks used to
                make the Site available, including by hacking or defacing any portion of the
                Site, or violate any requirement, procedure, or policy of such servers or
                networks.</p>



            <p>Restrict
                or inhibit any other person from using the Site.</p>



            <p>Reproduce,
                modify, adapt, translate, create derivative works of, sell, rent, lease, loan,
                timeshare, distribute, or otherwise exploit any portion of (or any use of) the
                Site except as expressly authorized herein, without Company’s express prior
                written consent.</p>



            <p>Reverse
                engineer, decompile or disassemble any portion of the Site, except where such
                restriction is expressly prohibited by applicable law.</p>



            <p>Remove
                any copyright, trademark, or other proprietary rights notice from the Site.</p>



            <p>Frame or
                mirror any portion of the Site, or otherwise incorporate any portion of the
                Site into any product or service, without the Company’s express prior written consent.</p>



            <p>Systematically
                download and store Site content.</p>



            <p>Use any
                robot, spider, site search/retrieval application or other manual or automatic
                device to retrieve, index, “scrape,” “data mine,” or otherwise gather Site
                content, or reproduce or circumvent the navigational structure or presentation
                of the Site, without Company’s express prior written consent. Notwithstanding
                the foregoing, and subject to compliance with any instructions posted in the
                robots.txt file located in the Site’s root directory, Company grants to the
                operators of public search engines permission to use spiders to copy materials
                from the Site for the sole purpose of (and solely to the extent necessary for)
                creating publicly available, searchable indices of such materials, but not
                caches or archives of such materials. The company reserves the right to revoke
                such permission either generally or in specific cases, at any time and without
                notice.</p>



            <p>You are
                responsible for obtaining, maintaining, and paying for all hardware and all
                telecommunications and other services needed to use the Site.</p>



            <p><strong>5.
                    Feedback.</strong> If you provide to us any ideas, proposals, suggestions, or other
                materials (“Feedback”), whether related to the Site or otherwise, you hereby
                acknowledge and agree that such Feedback is not confidential and that your
                provision of such Feedback is gratuitous, unsolicited, and without restriction,
                and does not place Company under any fiduciary or other obligation. You hereby
                grant to us a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual,
                irrevocable, transferable, and fully sub-licensable (through multiple tiers)
                license, without additional consideration to you or any third party, to
                reproduce, distribute, perform, and display (publicly or otherwise), create
                derivative works of, adapt, modify, and otherwise use, analyze, and exploit
                such Feedback, in any format or media now known or hereafter developed and for
                any purpose (including promotional purposes, such as testimonials). In
                addition, you hereby acknowledge and agree that such Feedback is not
                confidential and that your provision of such Feedback is gratuitous,
                unsolicited, and without restriction and does not place Company under any
                fiduciary or other obligation.</p>



            <p>You
                represent and warrant that you have all rights necessary to grant the licenses
                granted in this section and that your Feedback and your provision thereof
                through and in connection with the Site is not fraudulent, tortious, or
                otherwise in violation of any applicable law or any right of any third party.
                You further irrevocably waive any “moral rights” or other rights with respect
                to attribution of authorship or integrity of materials regarding Feedback that
                you may have under any applicable law under any legal theory.</p>



            <p><strong>6. Your
                    Limited Rights.</strong> Subject to your compliance with this Agreement, and solely for so
                long as you are permitted by Company to use the Site, you may view one (1) copy
                of any portion of the Site to which we provide you access under this Agreement,
                on any single device, solely for your personal, non-commercial use.</p>



            <p><strong>7.
                    Company’s Proprietary Rights.</strong> We and our suppliers own
                the Site, which is protected by proprietary rights and laws. Our trade names,
                trademarks, and service marks include “Purspek” and any associated logos. All
                trade names, trademarks, service marks, and logos on the Site not owned by us
                are the property of their respective owners. You may not use our trade names,
                trademarks, service marks, or logos in connection with any product or service
                that is not ours or in any manner that is likely to cause confusion. Nothing
                contained on the Site should be construed as granting any right to use any
                trade names, trademarks, service marks, or logos without the express prior
                written consent of the owner.</p>



            <p><strong>8.
                    Third-Party Materials; Links.</strong> Certain Site functionality
                may make available access to information, products, services, and other
                materials made available by third parties (“Third Party Materials”) or allow
                for the routing or transmission of such Third Party Materials, including via
                links. By using such functionality, you are directing us to access, route, and
                transmit to you the applicable Third Party Materials.</p>



            <p>We
                neither control nor endorse, nor are we responsible for, any Third Party
                Materials, including the accuracy, validity, timeliness, completeness,
                reliability, integrity, quality, legality, usefulness, or safety of Third Party
                Materials or any intellectual property rights therein. Nothing in this
                Agreement shall be deemed to be a representation or warranty by Company with
                respect to any Third Party Materials. We have no obligation to monitor Third
                Party Materials, and we may block or disable access to any Third Party
                Materials (in whole or part) through the Site at any time. In addition, the
                availability of any Third Party Materials through the Site does not imply our
                endorsement of, or our affiliation with, any provider of such Third Party
                Materials, nor does such availability create any legal relationship between you
                and any such provider.</p>



            <p>Your use
                of Third Party Materials is at your own risk and is subject to any additional
                terms, conditions, and policies applicable to such Third Party Materials (such
                as terms of service or privacy policies of the providers of such Third Party
                Materials).</p>



            <p><strong>9.
                    Disclaimer of Warranties.</strong> To the fullest extent permitted under
                applicable law: (a) the Site and any Third Party Materials are made available
                to you on an “As Is,” “Where Is,” and “Where Available” basis, without any
                warranties of any kind, whether express, implied, or statutory; and (b) Company
                disclaims all warranties with respect to the Site and any Third Party
                Materials, including the warranties of merchantability, fitness for a
                particular purpose, non-infringement, and title. All disclaimers of any kind
                (including in this section and elsewhere in this Agreement) are made for the
                benefit of both Company and its affiliates and their respective equity holders,
                managers, directors, officers, employees, affiliates, agents, representatives,
                licensors, suppliers, and service providers (collectively, the “Affiliated
                Entities”) and their respective successors and assigns.</p>



            <p>While we
                try to maintain the timeliness, integrity, and security of the Site, we do not
                guarantee that the Site is or will remain updated, complete, correct, or
                secure, or that access to the Site will be uninterrupted. The Site may include
                inaccuracies, errors, and materials that violate or conflict with this
                Agreement. Additionally, third parties may make unauthorized alterations to the
                Site. If you become aware of any such alteration, contact us at info@purspek.com&nbsp;with
                a description of such alteration and its location on the Site.</p>



            <p><strong>10.
                    Limitation of Liability.</strong> To the fullest extent permitted under
                applicable law: (a) Company will not be liable for any indirect, incidental,
                consequential, special, exemplary, or punitive damages of any kind, under any
                contract, tort (including negligence), strict liability, or other theory,
                including damages for loss of profits, use or data, loss of other intangibles,
                loss of security of submissions, even if advised in advance of the possibility
                of such damages or losses; (b) without limiting the foregoing, Company will not
                be liable for damages of any kind resulting from your use of or inability to
                use the Site or from any Third Party Materials, including from any virus that
                may be transmitted in connection therewith; (c) your sole and exclusive remedy
                for dissatisfaction with the Site or any Third Party Materials is to stop using
                the Site; and (d) the maximum aggregate liability of Company for all damages,
                losses, and causes of action, whether in contract, tort (including negligence)
                or otherwise, shall be the total amount, if any, paid by you to Company to use
                the Site. All limitations of liability of any kind (including in this section
                and elsewhere in this Agreement) are made for the benefit of both Company and
                the Affiliated Entities and their respective successors and assigns.</p>



            <p><strong>11.
                    Indemnity.</strong> To the fullest extent permitted under applicable law, you agree
                to defend, indemnify, and hold harmless Company and the Affiliated Entities,
                and their respective successors and assigns, from and against all claims,
                liabilities, damages, judgments, awards, losses, costs, expenses, and fees
                (including attorneys’ fees) arising out of or relating to (a) your use of, or
                activities in connection with, the Site (including your Feedback); and (b) any
                violation or alleged violation of this Agreement by you.</p>



            <p><strong>12.
                    Termination.</strong> This Agreement is effective until terminated. Company may
                terminate or suspend your use of the Site at any time and without prior notice,
                for any or no reason, including if Company believes that you have violated or
                acted inconsistently with the letter or spirit of this Agreement. Upon any such
                termination or suspension, your right to use the Site will immediately cease.
                Sections 2-5 and 7-14 shall survive any expiration or termination of this
                Agreement.</p>



            <p><strong>13.
                    Governing Law; Jurisdiction.</strong> This Agreement is governed by and shall be
                construed in accordance with the laws of the State of Florida, U.S.A., without
                regard to its principles of conflicts of law, and regardless of your location.
                You agree to the exclusive jurisdiction of the federal and state courts located
                in Florida, U.S.A., and waive any jurisdictional, venue, or inconvenient forum
                objections to such courts.</p>



            <p><strong>14.
                    Miscellaneous.</strong> This Agreement does not, and shall not be construed to, create
                any partnership, joint venture, employer-employee, agency, or
                franchisor-franchisee relationship between you and Company. If any provision of
                this Agreement is found to be unlawful, void, or for any reason unenforceable,
                that provision will be deemed severable from this Agreement and will not affect
                the validity and enforceability of any remaining provision. You may not assign,
                transfer, or sublicense any or all of your rights or obligations under this
                Agreement without our express prior written consent. We may assign, transfer,
                or sublicense any or all of our rights or obligations under this Agreement
                without restriction. No waiver by either party of any breach or default under
                this Agreement will be deemed to be a waiver of any preceding or subsequent
                breach or default. Any heading, caption, or section title contained herein is
                for convenience only, and in no way defines or explains any section or
                provision. All terms defined in the singular shall have the same meanings when
                used in the plural, where appropriate and unless otherwise specified. Any use
                of the term “including” or variations thereof in this Agreement shall be
                construed as if followed by the phrase “without limitation.” This Agreement,
                including any terms and conditions incorporated herein, is the entire agreement
                between you and Company relating to the subject matter hereof and supersedes
                any and all prior or contemporaneous written or oral agreements or
                understandings between you and Company relating to such subject matter. Notices
                to you (including notices of changes to this Agreement) may be made via posting
                to the Site or by e-mail (including in each case via links), or by regular mail.
                Without limitation, a printed version of this Agreement and of any notice given
                in electronic form shall be admissible in judicial or administrative
                proceedings based upon or relating to this Agreement to the same extent and
                subject to the same conditions as other business documents and records
                originally generated and maintained in printed form. The company will not be
                responsible for any failure to fulfill any obligation due to any cause beyond
                its control.</p>



            <p><strong>15.
                    Contact Us.</strong> If you have a question regarding the Site, please send an e-mail
                to&nbsp;<a href="mailto: info@purspek.com"><u>info@purspek.com</u></a></p>



            <p>Site ©
                2019 unless otherwise noted. &nbsp;All rights
                reserved.</p>

        </div>
    </div>
</div>