import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { RegisterFirstToWatch } from '../providers/constant';
import { RegisterComponent } from '../register/register.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { StorageService } from '../shared/services/storage.service';
import { AuthRequest, LoginService, AuthResponse } from '../login/login.service';

@Component({
  selector: 'app-firsttowatch',
  templateUrl: './firsttowatch.component.html',
  styleUrls: ['./firsttowatch.component.scss']
})
export class FirsttowatchComponent implements OnInit {
  @ViewChild('loginTemplate') public loginTemplate: ModalDirective;

  public loginMessage: any;
  public errorMessage: string = "";
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public loginFormGroup: FormGroup;
  public streamDetails;
  public iserrorMessage: boolean = false;

  private subs = new SubSink();

  constructor(private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private router: Router,
    private storageService: StorageService,
    private modalService: BsModalService,
    private loginService: LoginService
    ) { }

  ngOnInit(): void {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getLoginMessage();
    this.createLoginFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getLoginMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.loginMessage = messageList[MessageFor.LoginForm];
      });
  }

  private createLoginFormGroup(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      activationcode: [null],
      // email: null
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get loginControls() { return this.loginFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }


  public onSubmitClick(): void {
    if (this.loginFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;
    let appCode = this.storageService.get(AppStorage.appCodeLS, true);
    const formData = new FormData();
    formData.append("email", this.loginControls.email.value);
    formData.append("fullname", this.loginControls.password.value);
    //formData.append("appId", appCode);
    formData.append("register_type", 'F');
   formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", RegisterFirstToWatch);
    // const authRequest: AuthRequest = {};
    // authRequest.email = this.loginControls.email.value;
    // authRequest.password = this.loginControls.password.value;
    // authRequest.appId = APP_ID;
    // authRequest.postAction = LoginAction;

    this.subs.sink = this.loginService.registerFTW(formData)
      .subscribe(
        async (authResponse: AuthResponse) => {
         // console.log('authResponse: ', authResponse);
          if (authResponse.status) {
           
            this.errorMessage = authResponse.msg;
            this.iserrorMessage = true;
            // this.router.navigateByUrl('/live-event');
           // window.location.reload();
            // this.onCancel();
          } else {
            // document.getElementById('activation-code').hidden = false;

            this.iserrorMessage = true;
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
        //  console.log('error: ', error);
          this.showLoading = false;
          this.iserrorMessage = true;
        }
      );

      
  }
}
