import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addthis',
  templateUrl: './addthis.component.html',
  styleUrls: ['./addthis.component.scss']
})
export class AddthisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
