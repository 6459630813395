<div style="background-color:  #000000; background-size:cover;">
  <div style="text-align: center;">
    <!-- Header -->

  <img width="80%" src="../../assets/img/splash-logo.png">

    
    </div>
    

  </div>

