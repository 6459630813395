import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { MessageFor, AppStorage } from '../providers/enum';
import { changePasswordAction } from '../providers/constant';
import { StorageService } from '../shared/services/storage.service';
import { ChangePasswordService } from './services/change-password.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  //#region variables

  @ViewChild('loginTemplate') public loginTemplate: ModalDirective;

  public changePasswordMessage: any;
  public errorMessage: string;
  public isCheckValidation = false;
  public isChangePasswordSucc: boolean = false;
  public userData: any;

  public changePasswordFormGroup: FormGroup;
  public streamDetails: any;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private changePasswordService: ChangePasswordService,
    private loginService: LoginService,
    private storageService: StorageService,) { }

  ngOnInit(): void {
    let data = this.storageService.get(AppStorage.data, true);
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.userData = JSON.parse(data);
    this.getChangePasswordMessage();
    this.createChangePasswordFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getChangePasswordMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.changePasswordMessage = messageList[MessageFor.ChangePasswordForm];
      });
  }

  private createChangePasswordFormGroup(): void {
    this.changePasswordFormGroup = this.formBuilder.group({
      oldPassword: [null, [Validators.required, CommonUtilsService.passwordValidator]],
      newPassword: [null, [Validators.required, CommonUtilsService.passwordValidator]],
      confirmNewPassword: [null, [Validators.required, CommonUtilsService.passwordValidator]],
    }, {
      validator: [
        CommonUtilsService.mustMatch('newPassword', 'confirmNewPassword')
      ]
    });
  }

  //#endregion

  //#region public methods

  public get changePasswordControls() { return this.changePasswordFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onChangePasswordClick(): void {
    if (this.changePasswordFormGroup.invalid) {
      return;
    }

    this.isCheckValidation = true;

    let appCode = this.storageService.get(AppStorage.appCodeLS, true);

    const formData = new FormData();
    formData.append("userCode", this.userData.userCode);
    formData.append("oldPassword", this.changePasswordControls.oldPassword.value);
    formData.append("newPassword", this.changePasswordControls.newPassword.value);
    formData.append("confirmPassword", this.changePasswordControls.confirmNewPassword.value);
    //formData.append("appId", appCode);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", changePasswordAction);

    this.subs.sink = this.changePasswordService.changePassword(formData)
      .subscribe(
        (authResponse: any) => {
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);
            this.isChangePasswordSucc = true;
            this.errorMessage = authResponse.msg;

          } else {
            this.isChangePasswordSucc = false;
            this.errorMessage = authResponse.msg;
          }
        },
        (error) => {
         // console.log('error: ', error);
        }
      );
  }

  //#endregion

}
