import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { SubSink } from 'subsink';

import { MessageFor, AppStorage } from '../providers/enum';
import { dnpPaySuccessAction, stripeAPIPubKey, dnpTicketGenAction, guestTicketGenAction,DHARA_USERCODE} from '../providers/constant';
import { StorageService } from '../shared/services/storage.service';
import { GuestticketService } from './services/guestticket.service';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';

@Component({
  selector: 'app-guestticket',
  templateUrl: './guestticket.component.html',
  styleUrls: ['./guestticket.component.scss']
})
export class GuestticketComponent implements OnInit {

  //#region variables

  handler: any = null;
  public payFormGroup: FormGroup;
  public errorMessage: string;
  public isOtherOptionPaymentSelected: boolean = false;
  public selectedValue: string = '1';
  public paymentSuccess = false;
  public payMessage: any;
  public payAmount = 0;
  public streamDetails;
  public userData: any;
  public isCheckValidation = false;

  private subs = new SubSink();
  //#endregion

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private donatePerViewService: GuestticketService) { }


  ngOnInit(): void {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.createPayFormGroup();
    this.getRegisterMessage();

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

 //#endregion

  //#region private methods

  private getRegisterMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.payMessage = messageList[MessageFor.DonatePerViewModal];
      });
  }

  private createPayFormGroup(): void {
    this.payFormGroup = this.formBuilder.group({
      inputName : [null, Validators.required],
      inputEmail: [null, [Validators.required, CommonUtilsService.checkEmail]],
    }, {
      validator: [
        CommonUtilsService.checkEmail('inputEmail', true)
      ]
    });
  }

  public get payControls() { return this.payFormGroup.controls; }

  public registerFreeTicket() {

    var emailG = this.payFormGroup.controls.inputEmail.value;
   // alert(emailG);
    if(emailG == null){
      this.errorMessage = "Please Enter Email Address.";
      this.paymentSuccess = false;

    }
    else{
      var nameG = this.payFormGroup.controls.inputName.value;
      var emailG = this.payFormGroup.controls.inputEmail.value;
    
      var formDataG = new FormData();
      formDataG.append('appId', this.streamDetails[0].app_code)
      formDataG.append('postAction', "sendGuestTicketRequest")
      formDataG.append('gustName', nameG)
      formDataG.append('gustEmail', emailG)
    
      var errMsg = "";
     // console.log("sdfsdf=",formDataG);
     // alert("sdfsdfsf");
      this.subs.sink = this.donatePerViewService.dpvPayforGiftTicket(formDataG)
      .subscribe(
        (response: any) => {
          //console.log('response: ', response);
          if (response.status) {
            errMsg = response.msg;
           // alert(errMsg);
            this.errorMessage = errMsg;
            // this.onCancel();
            this.paymentSuccess = true;
          //  console.log(response);

          } else {
            errMsg = response.msg;
            this.errorMessage = errMsg;
           //  alert(errMsg);
            this.paymentSuccess = false;
           // console.log(response);
          }
        },
        (error) => {
         // console.log('erroreeeeee: ', error);
          this.errorMessage = errMsg;
        }
      );
    }
  }

  public emailChg()
  {
     // alert("change")
      this.errorMessage = "";
  }

  public onCancel(): void {
    this.modalRef.hide();
  }


  //#endregion

}

