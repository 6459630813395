import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { FetchJsonService } from '../../shared/services/fetch-json.service';
import { AuthService } from '../services/auth.service';
import { AppStorage, MessageFor } from '../../providers/enum';
import { forgotPasswordAction } from '../../providers/constant';
import { RegisterComponent } from '../../register/register.component';
import { LoginComponent } from '../../login/login.component';
import { StorageService } from '../../shared/services/storage.service';

@Component({
  selector: 'app-forgot-password-route',
  templateUrl: './forgot-password-route.component.html',
  styleUrls: ['./forgot-password-route.component.scss']
})
export class ForgotPasswordRouteComponent implements OnInit {

  //#region variables

  public forgotPasswordMessage: any;
  public errorMessage: string;
  public isProcessCompleted = false;
  public isCheckValidation = false;
  public forgotPasswordFormGroup: FormGroup;
  public streamDetails;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private router: Router,
    private storageService: StorageService,
    private forgotPasswordService: AuthService) { }

  ngOnInit(): void {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getForgotPasswordMessage();
    this.createForgotPasswordFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getForgotPasswordMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.forgotPasswordMessage = messageList[MessageFor.ForgotPasswordForm];
      });
  }

  private createForgotPasswordFormGroup(): void {
    this.forgotPasswordFormGroup = this.formBuilder.group({
      email: [null, [Validators.required]]
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get forgotPasswordControls() { return this.forgotPasswordFormGroup.controls; }

  public onForgotPasswordClick(): void {
    if (this.forgotPasswordFormGroup.invalid) {
      return;
    }

    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("emailOrusername", this.forgotPasswordControls.email.value);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", forgotPasswordAction);

    this.subs.sink = this.forgotPasswordService.forgotPassword(formData)
      .subscribe(
        (authResponse: any) => {
          if (authResponse.status) {
            this.isProcessCompleted = true;
            this.errorMessage = "New password has been sent to your registered email address.";
          } else {
            this.isProcessCompleted = false;
            this.errorMessage = "Sorry, given email address is not present in our record.";
          }
        },
        (error) => {
          this.isProcessCompleted = false;
         // console.log('error: ', error);
        }
      );
  }

  public onLoginClick(): void {
    this.router.navigateByUrl('/auth/login/celebbroadway');
  }

  public onCreateAccount(): void {
    this.router.navigateByUrl('/auth/register/celebbroadway');
  }

  //#endregion

}
