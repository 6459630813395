<div class="modal-content">

    <div class="modal-body">
        
        <div class="card p-4 promo"><br>
            
            <div class="card-body" id="promo" style="text-align: center; color: white !important; font-size: 19px !important; font-weight: normal;">
                 
              <img src="../../assets/event2.png">
                <br><br>
                    <button id="cancel-btn" class="btn cancel-btn px-4"  (click)="onCancel()">Close</button>   

            </div>
            
        </div>
    </div>

</div>