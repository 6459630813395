<div class="modal-content">

    <div class="modal-body">
        
        <div class="card p-4 promo"><br>
            
            <div class="card-body" id="promo" style="text-align: center; color: black !important; font-size: 18px !important;">
                 
                <img width="80%"  src="../../assets/ride-itinerary.png"><br><Br>
            </div>
            <div class="card-body" id="promo" style="text-align: left; color: black !important; font-size: 18px !important;">
            Washington, DC - (HD of Washington, DC) Kick-off October 29 to <br>
            Fayetteville, NC (Fort Bragg HD) October 29-30 to <br>
            Atlanta (Thunder Tower West HD) October 30-31, Nov 1 to <Br>
            Mobile, AL (Mobile Bay HD) Nov 1-2 to<br>
            New Orleans, LA (TBD) Nov 2-3 to <br>
            Houston, Texas (TBD) Nov 3,4-5 to <br>
            Sonora, TX - Nov 5-6 (overnight only/no events) to <br>
            El Paso (TBD) Nov 6-7 to <br>
            Phoenix (TBD) Nov 7-8 to <br>
            Las Vegas (TBD) Nov 8-9 to <br>
            Los Angeles (TBD) Nov 10 <br>
            Los Angeles (TBD) Nov 11 <br>
            Los Angeles (Thunder Studios) Nov 12 <br>

                
            </div>
            <div class="card-body" id="promo" style="text-align: center; color: black !important; font-size: 16px !important;">
            <button id="cancel-btn" class="btn cancel-btn px-4"  (click)="onCancel()">Close</button>   

            </div>

</div>
</div>

</div>