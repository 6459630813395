<div class="modal-content">
  <div class="modal-body">
    <div class="card p-4 login">
      <div class="card-body" id="login">

        <h1 *ngIf="!paymentSuccess" class="loginLabel">Register for Gift Ticket </h1>
        <br>
        <div class="form-group">

          <form [formGroup]="payFormGroup" autocomplete="off">
            <div class="form-group" *ngIf="!paymentSuccess" >
              <label class="form-label mb-1 loginLabel" for="inputUserName">Email Address <span
                  class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                id="inputUserName" placeholder="Email Address" formControlName="inputEmail"
                [ngClass]="{ 'is-invalid': isCheckValidation && payControls.inputEmail.errors }" 
                (click)="emailChg()">
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputEmail.errors) ||
                  (!!payControls?.inputEmail.touched && !!payControls?.inputEmail.invalid)">
                <ng-container class="pt-2" *ngIf="!!payControls?.inputEmail.errors.required">
                  {{ payMessage.inputEmail.errors.required }}
                </ng-container>
              </small>
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputEmail.errors) ||
                  (!!payControls?.inputEmail.touched && !!payControls?.inputEmail.invalid)">
                <ng-container class="pt-2" *ngIf="payControls?.inputEmail.errors.checkEmail">
                  {{ payMessage.inputEmail.errors.invalid }}
                </ng-container>
              </small>
            </div>

            <div class="form-group" *ngIf="!paymentSuccess" >
              <label class="form-label mb-1 loginLabel" for="inputName">Name <span
                  class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" type="text" class="form-control" id="inputName"
                placeholder="Full Name" formControlName="inputName"
                [ngClass]="{ 'is-invalid': isCheckValidation && payControls.inputName.errors }" 
                (click)="emailChg()">
              <small class="form-error-text" *ngIf="(isCheckValidation && !!payControls?.inputName.errors) ||
                  (!!payControls?.inputName.touched && !!payControls?.inputName.invalid)">
                <ng-container class="pt-2" *ngIf="!!payControls?.inputName.errors.required">
                  {{ payMessage.inputName.errors.required }}
                </ng-container>
              </small>
            </div>

            <br>
            <div class="col-md-12" style="text-align: center;"> 
              
             
              <div *ngIf="!paymentSuccess" style="text-align: center;">&nbsp;
              
                
          

                <button id="register-btn" (click)="registerFreeTicket()" class="btn px-4">Register</button>
                <button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button>

              </div>
              <div class="text-center">
                <p id="error-message">
                  {{ errorMessage }}
                </p>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>