import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/Modal';
import { SubSink } from 'subsink';

import { AppStorage, MessageFor } from '../providers/enum';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { StorageService } from '../shared/services/storage.service';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  //#region variables

  public supportMessage: any;
  public isCheckValidation = false;
  public errorMessage: string = '';
  public showLoading = false;
  public userData: any;
  public streamDetails: any;
  public supportFormGroup: FormGroup;
  public streamTitle: string;
  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private fetchJsonService: FetchJsonService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private supportService: SupportService,
    private modalRef: BsModalRef,) { }

  ngOnInit(): void {
    let data = this.storageService.get(AppStorage.data, true);
    
    let eventData = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(eventData);
    this.streamTitle = this.streamDetails[0].stream_title;

    this.userData = JSON.parse(data);
    this.getSupportFormMessage();
    this.createSupportFormGroup();
    if (this.userData && this.userData.name && this.userData.email) {
      this.fillSupportForm();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getSupportFormMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.supportMessage = messageList[MessageFor.SupportForm];
      });
  }

  private createSupportFormGroup(): void {
    this.supportFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, CommonUtilsService.checkEmail]],
      message: [null, Validators.required],
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  private fillSupportForm(): void {
    this.supportFormGroup.patchValue({
      name: this.userData.name,
      email: this.userData.email
    });

    this.supportControls.name.disable();
    this.supportControls.email.disable();
  }

  //#endregion

  //#region public methods

  public get supportControls() { return this.supportFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onSubmitClick(): void {
    if (this.supportFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    /*
    let obj = {
      appName: this.streamTitle,
      from_name: this.supportControls.name.value,
      from_email: this.supportControls.email.value,
      email_message: this.supportControls.message.value

    } */
    var formDataG = new FormData();
    formDataG.append('appName', this.streamTitle);
    formDataG.append('from_name', this.supportControls.name.value);
    formDataG.append('from_email', this.supportControls.email.value);
    formDataG.append('email_message', this.supportControls.message.value);

    //console.log("sup obj = ", formDataG);
    this.subs.sink = this.supportService.sendSupportMail(formDataG)
      .subscribe(
        (response: any) => {
          //console.log('response email: ', response);
          if (response.status === 0) {
            this.errorMessage = 'Oops something went wrong please try again later.'
          } else {
            this.errorMessage = response.msg;
          } 
          this.showLoading = false;
        }, (error) => {
          //console.log('error: ', error);
          this.errorMessage = 'oops something went wrong please try again later.'
          this.showLoading = false;
        });
  }

  //#endregion

}
