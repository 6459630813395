import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APP_URLS } from '../../providers/constant';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  private apiUrl = `${APP_URLS.WEB_API_URL}`;

  constructor(
    private http: HttpClient
  ) { }

  public changePassword(authRequest): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users`, authRequest);
  }

}
