import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    /** check local storage is available. */
    public isLocalStorageAvailable(): boolean {
        if (typeof (Storage) !== 'undefined') {
            return true;
        } else {
            return false;
        }
    }

    public set(key: any, value: any, useLocal: boolean): void {
        if (this.isLocalStorageAvailable()) {
            if (useLocal) {
                localStorage.setItem(key, value);
            } else {
                sessionStorage.setItem(key, value);
            }
        } else {
            throw new Error('No Web Storage support..');
        }
    }

    /** get string from local storage. */
    public get(key: any, useLocal: boolean): string {
        if (this.isLocalStorageAvailable()) {
            if (useLocal) {
                return localStorage.getItem(key);
            } else {
                return sessionStorage.getItem(key);
            }
        } else {
            throw new Error('No Web Storage support..');
        }
    }

    /** remove item from local storage. */
    public remove(key: any, useLocal: boolean): void {
        if (this.isLocalStorageAvailable()) {
            if (useLocal) {
                localStorage.removeItem(key);
                // localStorage.clear();
            } else {
                sessionStorage.removeItem(key);
                sessionStorage.clear();
            }
        } else {
            throw new Error('No Web Storage support..');
        }
    }
    
}