import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { FetchJsonService } from '../../shared/services/fetch-json.service';
import { AuthService, AuthResponse } from '../services/auth.service';
import { AppStorage, MessageFor } from '../../providers/enum';
import { LoginAction } from '../../providers/constant';
import { StorageService } from '../../shared/services/storage.service';
import { StreamDetailsService } from '../../shared/services/stream-details.service';

@Component({
  selector: 'app-login-route',
  templateUrl: './login-route.component.html',
  styleUrls: ['./login-route.component.scss']
})
export class LoginRouteComponent implements OnInit {

  //#region variables

  public loginMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public loginFormGroup: FormGroup;
  public streamDetails;
  public domain: string;
  public title: string;
  public myAngularxQrCode: string = null;
  public DomainName: string;
  public streamCorrect = true;
  public userData: any;
  private subs = new SubSink();
  public isUserLogin = false;
  //#endregion

  //#region life-cycle hook

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private fetchJsonService: FetchJsonService,
    private storageService: StorageService,
    private streamDetailsService: StreamDetailsService,
    private loginService: AuthService) {

    //let fullHost = this.location._platformStrategy._platformLocation.location.hostname;

    let fullHost = "celebration.swigit.com";
    let parts = fullHost.split('.');
    if (parts && parts.length > 0 && parts[0] !== "localhost") {
      let url = this.router.url;
      this.domain = parts[0];
      //  this.title = url.replace("/", "");
    }

    let apptitlel = this.route.snapshot.params.apptitle;
    this.title = apptitlel;

    // This code for localhost
    this.route.queryParams.subscribe(params => {
      if (params && params.domain && params.title) {
        this.domain = params.domain;
        this.title = params.title;
      }
    }, (error: any) => {
      // console.log('error: ', error);
    });

   // this.domain = "celebration";
    //this.title = "celebbroadway";

    this.storageService.set(AppStorage.siteDomain, this.domain, true);
    this.storageService.set(AppStorage.siteTitle, this.title, true);


  }

  ngOnInit(): void {


    this.setStreamDetails();
    //this.href = this.router.url;
    this.DomainName = this.domain;
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    //console.log("stream on login route=", this.streamDetails);
    let streamTitle = this.storageService.get(AppStorage.siteTitle,  true);
    this.title = streamTitle;

    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);

    if (this.userData) {
      this.isUserLogin = true;
    }
    else
    {
      this.isUserLogin = false;
    }

    if (!this.userData || !this.userData.userCode) { } else {
      this.errorMessage = "You are already logged in. You can start purchasing ticket now.";
    }


    this.getLoginMessage();
    this.createLoginFormGroup();


  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getLoginMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.loginMessage = messageList[MessageFor.LoginForm];
      });
  }

  private createLoginFormGroup(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      activationcode: [null],
      // email: null
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion
  private async setStreamDetails() {
    //console.log("dd=");
    let data = this.storageService.get(AppStorage.data, true);

    this.streamCorrect = true;
    try {
      let result = await this.streamDetailsService.getStreamDetails().toPromise();
      // console.log("stream detail:", result);
      if (result.app.status) {



        //  background: url(https://imagescdn2.swigit.com/LiveEvent/HDPoster/angielostgirls) no-repeat #1e1e1e;
        this.storageService.set(AppStorage.streamDetails, JSON.stringify(result.app.all_streams.latest_streams), true);
        //this.storageService.get(AppStorage.streamDetails, true);
        //console.log("err=", AppStorage.streamDetails);
       
      }
      else {
        this.streamCorrect = false;
      }
    } catch (error) {
      //console.log('error: ', error);
      this.showLoading = false;
      this.streamCorrect = false;
    }


  }
  //#region public methods

  public get loginControls() { return this.loginFormGroup.controls; }

  public onActiveClick(): void {

    this.showLoading = true;
    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("emailOrusername", this.loginControls.email.value);
    formData.append("accountActivationCode", this.loginControls.activationcode.value);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", 'validateAccountActivationCode');
    // const authRequest: AuthRequest = {};
    // authRequest.email = this.loginControls.email.value;
    // authRequest.password = this.loginControls.password.value;
    // authRequest.appId = APP_ID;
    // authRequest.postAction = LoginAction;

    this.subs.sink = this.loginService.authenticateUser(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);

            // this.router.navigateByUrl('/live-event');
            window.location.reload();
            // this.onCancel();
          } else {
            // document.getElementById('activation-code').hidden = false;

            this.isSignUp = false;
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
          //  console.log('error: ', error);
          this.showLoading = false;
        }
      );


  }

  public onCreateAccount(): void {
    this.router.navigateByUrl('/auth/register/celebbroadway');
  }


  public onLoginClick(): void {
    if (this.loginFormGroup.invalid) {
      return;
    }

   /// console.log("strmmmmm=", this.streamDetails[0]);
    this.showLoading = true;
    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("email", this.loginControls.email.value);
    formData.append("password", this.loginControls.password.value);
    formData.append("appId", "7f912b2a598f9397d282950787b6b9d0");
    formData.append("postAction", LoginAction);
    // const authRequest: AuthRequest = {};
    // authRequest.email = this.loginControls.email.value;
    // authRequest.password = this.loginControls.password.value;
    // authRequest.appId = APP_ID;
    // authRequest.postAction = LoginAction;

    this.subs.sink = this.loginService.authenticateUser(formData)
      .subscribe(
        async (authResponse: AuthResponse) => {
          // console.log('authResponse: ', authResponse);
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);
            try {
              let result = await this.loginService.checkEventIsBuyed(authResponse.data.userCode).toPromise();
              if (result.app.status) {
                this.storageService.set(AppStorage.isLiveEventBuyed, result.app.all_streams.current_page_stream.is_live_event_buyed, true);
              }
            } catch (error) {
              //console.log('error: ', error);
            }

            let lastRoute = this.storageService.get(AppStorage.lastVisitedRoute, true);
            if (lastRoute) {
              this.router.navigateByUrl(`/${lastRoute}/${this.title}`);
            } else {
              this.router.navigateByUrl(`/donate-per-view/${this.title}`);
            }

            // window.location.reload();
            // this.onCancel();
          } else {
            // document.getElementById('activation-code').hidden = false;

            if (authResponse.msg != "Sorry, invalid email address or password.")
              this.isSignUp = false;
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
          //  console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }

  public onForgotPassword(): void {
    this.router.navigateByUrl('/auth/forgot-password');
  }

  //#endregion

}
