<div class="container mt-5">
  <h2>Stripe Checkout</h2>
  <div class="row mt-5">
    <div class="col-md-4">
      <button (click)="pay(20)" class="btn btn-primary btn-block">Pay $20</button>
    </div>
    <div class="col-md-4">
      <button (click)="pay(30)" class="btn btn-success btn-block">Pay $30</button>
    </div>
    <div class="col-md-4">
      <button (click)="pay(50)" class="btn btn-info btn-block">Pay $50</button>
    </div>    
  </div>
 
</div>

<router-outlet></router-outlet>