import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchJsonService {
    private messagePath = './assets/json/message.json';

    constructor(private http: HttpClient) {}

    public getMessagesList(): Observable<any> {
      return this.http.get(`${this.messagePath}?cacheBuster=${Math.random()}`);
    }
}
