import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { FetchJsonService } from '../../shared/services/fetch-json.service';
import { AuthService, AuthResponse } from '../services/auth.service';
import { AppStorage, MessageFor } from '../../providers/enum';
import { LoginAction } from '../../providers/constant';
import { StorageService } from '../../shared/services/storage.service';

@Component({
  selector: 'app-logout-route',
  templateUrl: './logout-route.component.html',
  styleUrls: ['./logout-route.component.scss']
})
export class LogoutRouteComponent implements OnInit {

  //#region variables

  public loginMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public loginFormGroup: FormGroup;
  public streamDetails;
  public domain: string;
  public title: string;
  public myAngularxQrCode: string = null;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private fetchJsonService: FetchJsonService,
    private storageService: StorageService,
    private loginService: AuthService) { }

  ngOnInit(): void {

    this.storageService.remove(AppStorage.data, true);
    this.storageService.remove(AppStorage.isLiveEventBuyed, true);
    this.errorMessage = "You are successfully logged out.";
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion



  //#endregion

  //#region public methods






  //#endregion

}
