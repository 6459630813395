import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '../shared/services/storage.service';
import { AppStorage, StreamType } from '../providers/enum';
import { RegisterService } from '../register/register.service';
import { SubSink } from 'subsink';
import { NewsResponse } from '../register/register.service';

@Component({
  selector: 'app-register-message',
  templateUrl: './register-message.component.html',
  styleUrls: ['./register-message.component.scss']
})
export class RegisterMessageComponent implements OnInit {

  public userData: any;
  public showLoading: boolean;
  public errorMessage: string;
  public registerFormGroup: FormGroup;
  private subs = new SubSink();
  public newsStatus: boolean;
  public enableR: boolean;

  constructor( private storageService: StorageService,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.createRegisterFormGroup();
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);

    if(this.userData.newsletter_flag === "Y")
      this.newsStatus = true;
    else
      this.newsStatus = false;
    
  }

  
  public onCancel(): void {
    this.modalRef.hide();
  }

  private createRegisterFormGroup(): void {
    this.registerFormGroup = this.formBuilder.group({
      inputNewsletter: [null],
      
    });
  }
  
  public onCheckbooxClick()
  {
    var vl = this.registerFormGroup.controls.inputNewsletter.value+"";
    //alert(vl);
    if(vl === "true")
      this.enableR = false;
    else  
      this.enableR = true;
     // alert(this.enableR);
  }
  public get registerControls() { return this.registerFormGroup.controls; }

  public onRegisterClick(): void {
    var checkboxVal = this.registerFormGroup.controls.inputNewsletter.value;
    var updateStatus = "N";
    //alert("sdfsdf"+checkboxVal);
    
    if(checkboxVal)
      updateStatus = "Y";
    else
      updateStatus = "N";

  

    this.showLoading = true;
    
    
    const formData = new FormData();
    formData.append("userCode", this.userData.userCode);
    formData.append("newsletterStatus", updateStatus);
    // formData.append("appId", this.streamDetails[0].app_code);
   
    
    this.subs.sink = this.registerService.setNewsletterStatus(formData)
      .subscribe(
        (authResponse: NewsResponse) => {
          if (authResponse.status) {
           
            this.errorMessage = authResponse.msg;
            // this.errorMessage = "Signup Successfully done. Please check your email for Access Code and come back to Login here.";
           // window.location.reload();
          } else {
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );

    }

}
