import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PromovideoComponent } from 'src/app/promovideo/promovideo.component';
import { RegisterMessageComponent } from 'src/app/register-message/register-message.component';
import { RegisterComponent } from 'src/app/register/register.component';
import { VimeoplayerComponent } from 'src/app/vimeoplayer/vimeoplayer.component';
import { StreamDetailsService } from '../../services/stream-details.service';
import { StorageService } from '../../services/storage.service';
import { AppStorage, StreamType } from '../../../providers/enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //#region variables

  @Input() streamDetailsFull: any;
  @Input() isHomePage: any;
  public modalRef: BsModalRef;
  public iosAppAvailable: boolean = false;
  public androidAppAvailable: boolean = false;
  public eventFooterLink = true;
  public rokuAppAvailable: boolean = false;
  public firetvAppAvailable: boolean = false;
  public userData: any;
  public streamDetails: any;
  public streamDescFull: any;
  public textBelowPoster: any;
  public appBundlesHPFoot: any;
  public clientDetailsFoot: any;
  public footerTemp: any;
  public isUserLogin: boolean = false;
  //#endregion

  //#region life-cycle hook

  constructor(private modalService: BsModalService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.setStreamDetails();

    let data = this.storageService.get(AppStorage.data, true);
    if (data) {
      this.userData = JSON.parse(data);
      this.isUserLogin = true;
    }

    let clientDetails = this.storageService.get(AppStorage.clientDetails, true);
    this.clientDetailsFoot = JSON.parse(clientDetails);
    this.appBundlesHPFoot =  this.clientDetailsFoot.data.appBundles;

    this.footerTemp = this.appBundlesHPFoot.bundle_template_id;
  }

  //#endregion

  //#region private methods

  private async setStreamDetails() {

   // console.log("full = ", this.streamDetailsFull);
  /*  if (this.streamDetailsFull.data.stream_design.footer_link_or_text === 'L')
      this.eventFooterLink = true;
    else
      this.eventFooterLink = false;
*/
//console.log("lo=", this.isHomePage);
if(!this.isHomePage)
{
    if (this.streamDetailsFull.data.appDesigns.android_app_avail === 'Y')
      this.androidAppAvailable = true;
    else
      this.androidAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.ios_app_avail === 'Y')
      this.iosAppAvailable = true;
    else
      this.iosAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.roku_app_avail === 'Y')
      this.rokuAppAvailable = true;
    else
      this.rokuAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.firetv_app_avail === 'Y')
      this.firetvAppAvailable = true;
    else
      this.firetvAppAvailable = false;
  }
  }

  public onVideoLinkClick(videoURL, videoType)
  {
      let initialState = { url: videoURL };
      //alert(videoType);
      if(videoType == 'V')
      {
        this.modalRef = this.modalService.show(
          VimeoplayerComponent,
          //PromovideoComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
        );
      }
      else{
        this.modalRef = this.modalService.show(
          PromovideoComponent,
           Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
      );
      }

  }
  //#endregion

  public openRegisterModal(): void {
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openRegisterMessageModal(): void {
    this.modalRef = this.modalService.show(
      RegisterMessageComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }
  
  
}
