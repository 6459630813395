import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AppStorage } from '../providers/enum';
import { StorageService } from '../shared/services/storage.service';


declare const Clappr: any;
declare const videojs: any;

@Component({
  selector: 'app-promovideo',
  templateUrl: './promovideo.component.html',
  styleUrls: ['./promovideo.component.scss']
})
export class PromovideoComponent implements OnInit {

  //#region variables

  @ViewChild('promovideoTemplate') public promovideoTemplate: ModalDirective;

  public player;
  public streamDetails: any;
  public streamURL: any;

  //#endregion

  //#region life-cycle hook

  constructor(private modalRef: BsModalRef,
    public options: ModalOptions,
    private storageService: StorageService) { }

  ngOnInit(): void {
    let eventData = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(eventData);
    //console.log(this.options.initialState);

    // let streamURL = this.storageService.get(AppStorage.streamPlayUrl, true);
    // let streamURL = "https://vcnc2ctv.teleosmedia.com/stream/c2ctv/chords2cure/playlist.m3u8";
    this.streamURL = this.options.initialState;

    //console.log("streamURL:::", streamURL);
    
    var playerElement = document.getElementById("example-video");

    // var player = new Clappr.Player({
    //   source: 'http://storec2ctv.teleosmedia.com/vod/C2CTV/C2C_Sizzle_19_cut_6/playlist.m3u8',
    //   // poster: 'http://clappr.io/poster.png',
    //   autoPlay: true,
    //   mute: false,
    //   height: '85vh',
    //   width: '100vw'
    // });
     //this.player.chromecast();

    this.player = videojs(playerElement, {
      techOrder: [ "html5"],
      autoplay: true,
      muted: false,
      loop: false,
      fluid: true,
      controls: true,
      preload: "auto",
     // chromecast: {
     // },
      sources: [{
        type: "application/x-mpegURL",
        src: this.streamURL.url
      }],
      /* plugins: {
         chromecast: {
           receiverAppID: '646E7AC8',
           addButtonToControlBar: true,
         },
         airPlay: {
           addButtonToControlBar: true,
         }
       }*/
    })
    //@ts-ignore
    window.SILVERMINE_VIDEOJS_CHROMECAST_CONFIG = {
      preloadWebComponents: true,
    };
  }

  ngOnDestroy(): void {
  }

  public onCancel(): void {
    if (this.player) {
      this.player.dispose();
    }
    this.modalRef.hide();
  }

  //#endregion

}
