import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //#region life-cycle hook

  constructor() { }

  ngOnInit() {
  }

  //#endregion
}
