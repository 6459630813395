<div class="modal-content">

    <div class="modal-body">
        
        <div class="card p-4 promo"><br>
            
            
            <div class="card-body" id="promo" style="text-align: left; color: black !important; font-size: 18px !important;">
                <div class="row">
                    <div class="col-md-6"><a href="https://hungrysnacks100.com/" target="_blank"><img src="https://swigappmanager.com/images/swee-sponsor-1.png"></a></div>
                    <div class="col-md-6"><a href="https://cuztumlycrafted.com/" target="_blank"><img src="https://swigappmanager.com/images/swee-sponsor-2.png"></a><br></div>
                </div>

                    
                    
                
            </div>
            <div class="card-body" id="promo" style="text-align: center; color: black !important; font-size: 16px !important;">
            <button id="cancel-btn" class="btn cancel-btn px-4"  (click)="onCancel()">Close</button>   

            </div>

</div>
</div>

</div>