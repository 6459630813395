import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule, BsDropdownConfig  } from 'ngx-bootstrap/dropdown';

import { NumericDirective } from './directives/numeric.directive';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
// import { SpinnerComponent } from '../pages/spinner/spinner.component';

@NgModule({
  declarations: [
    NumericDirective,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    // SpinnerComponent,
    BsDropdownModule,
    CommonModule
  ],
  exports: [
    NumericDirective,
    HeaderComponent,
    FooterComponent
  ],
  providers: [
    BsDropdownConfig
  ]
})

export class SharedModule { }
