import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { AppStorage } from '../providers/enum';
import { StorageService } from '../shared/services/storage.service';

declare const videojs: any;

@Component({
  selector: 'app-extravideo',
  templateUrl: './extravideo.component.html',
  styleUrls: ['./extravideo.component.scss']
})
export class ExtravideoComponent implements OnInit {

  //#region variables

  @ViewChild('extravideoTemplate') public extravideoTemplate: ModalDirective;

  public player;
  public streamDetails: any;

  //#endregion

  constructor(private modalRef: BsModalRef,
    private storageService: StorageService) { }

  ngOnInit(): void {
    let eventData = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(eventData);
    var playerElement = document.getElementById("extra-video");

    
    this.player = videojs(playerElement, {
      techOrder: ["chromecast", "html5"],
      autoplay: true,
      muted: false,
      loop: false,
      fluid: true,
      controls: true,
      preload: "auto",
      chromecast: {
      },
      sources: [{
        type: "application/x-mpegURL",
        src: this.streamDetails[0].stream_design.extra_video_btn_url
      }],
      plugins: {
        chromecast: {
          receiverAppID: '646E7AC8',
          addButtonToControlBar: true,
        },
        airPlay: {
          addButtonToControlBar: true,
        }
      }
    })
    //@ts-ignore
    window.SILVERMINE_VIDEOJS_CHROMECAST_CONFIG = {
      preloadWebComponents: true,
    };
  }

  public onCancel(): void {
    if (this.player) {
      this.player.dispose();
    }
    this.modalRef.hide();
  }


}
