import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppStorage } from '../providers/enum';
import { StreamDetailsService } from '../shared/services/stream-details.service';
import { StorageService } from '../shared/services/storage.service';

declare const videojs: any;

@Component({
  selector: 'app-live-video-player',
  templateUrl: './live-video-player.component.html',
  styleUrls: ['./live-video-player.component.scss']
})
export class LiveVideoPlayerComponent implements OnInit, OnDestroy {

  //#region variables

  public player;
  public streamDetails: any;

  //#endregion

  //#region life-cycle hook

  constructor(private modalRef: BsModalRef,
    private streamDetailsService: StreamDetailsService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    let eventData = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(eventData);
    var playerElement = document.getElementById("live-video");

    let result2 = this.streamDetailsService.trackUserAccessVideo().toPromise();

   // console.log("str=", this.streamDetails[0].stream_url);
    this.player = videojs(playerElement, {
      techOrder: [ "html5"],
      autoplay: true,
      muted: false,
      loop: false,
      fluid: true,
      controls: true,
      preload: "auto",
     
      sources: [{
        type: "application/x-mpegURL",
        src: this.streamDetails[0].stream_url // "https://2bg214bv7ny6dnral5r7-mtw9af.p5cdn.com/live/internal_recordings_live/ef5077a11eb774dddb991ba2eaa7f5b6-internal/master.m3u8"// this.streamDetails[0].eventStreamUrl ////vcnc2ctv.teleosmedia.com/stream/c2ctv/chan01/playlist.m3u8"
      }],
      plugins: {
       
      }
    })
    //@ts-ignore
    
  }

  ngOnDestroy(): void {
  }

  //#endregion

  //#region public methods

  public onCancel(): void {
    if (this.player) {
      this.player.dispose();
    }
    this.modalRef.hide();
  }

  //#endregion

}
