import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ridekindinfo',
  templateUrl: './ridekindinfo.component.html',
  styleUrls: ['./ridekindinfo.component.scss']
})
export class RidekindinfoComponent implements OnInit {

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  
  public onCancel(): void {
    this.modalRef.hide();
  }

}
