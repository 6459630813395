import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { FetchJsonService } from '../shared/services/fetch-json.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { userFreeCodeAction, LoginAction, userAllCodeAction } from '../providers/constant';
import { StorageService } from '../shared/services/storage.service';
import { DonatePerViewService } from '../donate-per-view-modal/services/donate-per-view.service';
import { LiveVideoPlayerComponent } from '../live-video-player/live-video-player.component';
import { LoginService } from '../login/login.service';
import { VimeoplayerComponent } from '../vimeoplayer/vimeoplayer.component';

@Component({
  selector: 'app-enter-ticket-code-all',
  templateUrl: './enter-ticket-code-all.component.html',
  styleUrls: ['./enter-ticket-code-all.component.scss']
})
export class EnterTicketCodeAllComponent implements OnInit, OnDestroy {

    //#region variables

    public ticketCodeMessage: any;
    public errorMessage: string;
    public showLoading = false;
    public isCheckValidation = false;
    public isSignUp: boolean = true;
    public userData: any;
    public ticketCodeFormGroup: FormGroup;
    public streamDetails;
  
    private subs = new SubSink();
  
    //#endregion
  
    //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private loginService: LoginService,
    private storageService: StorageService,
    private donatePerViewService: DonatePerViewService) { }

  ngOnInit(): void {
    
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getTicketCodeMessage();
    this.createTicketCodeFormGroup();
  }

  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getTicketCodeMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.ticketCodeMessage = messageList[MessageFor.TicketCodeForm];
      });
  }

  private createTicketCodeFormGroup(): void {
    this.ticketCodeFormGroup = this.formBuilder.group({
      activationcode: [null, [Validators.required]],
    });
  }

  //#endregion

  //#region public methods

  public get ticketCodeControls() { return this.ticketCodeFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onSubmitClick(): void {
    this.errorMessage = "";
    //alert("in");
    if (this.ticketCodeFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    let appCode = this.storageService.get(AppStorage.appCodeLS, true);

    const formData = new FormData();
    if(this.userData)
      formData.append("userCode", this.userData.userCode);
    formData.append("freeCode", this.ticketCodeControls.activationcode.value);
    formData.append("streamGuid", this.streamDetails[0].stream_guid);
    //formData.append("appId", appCode);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", userAllCodeAction);

    //console.log('formData: ', formData);

    this.subs.sink = this.donatePerViewService.freeCodeCheck(formData)
      .subscribe(
        async (response: any) => {
         // console.log('response: ', response);
          if (response.status == 1) {
            this.modalRef.hide();
            try {
              
              
                let streamU = response.data.strURL;
               // alert(streamU);
                let initialState = { url: streamU };
                this.modalRef = this.modalService.show(
                  VimeoplayerComponent,
                  //PromovideoComponent,
                  Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState  })
                );
                //this.storageService.set(AppStorage.isLiveEventBuyed, result.app.all_streams.current_page_stream.is_live_event_buyed, true);
              
            } catch (error) {
              //console.log('error: ', error);
            }
            
          } else {
            this.ticketCodeFormGroup.reset();
            if(response.status == 2)
              this.errorMessage = "Ticket code is Invalid, please use a valid code.";
            else  if(response.status == 3)
              this.errorMessage = "Ticket code is expired, please use a valid code.";
            else
              this.errorMessage = response.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }

  //#endregion
}
