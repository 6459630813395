import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { StorageService } from '../shared/services/storage.service';
import { APP_URLS } from '../providers/constant';

import { AppStorage } from '../providers/enum';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authApiUrl = `${APP_URLS.WEB_API_URL}`;
  public streamDetails;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
  }

  public authenticateUser(authRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.authApiUrl}/users`, authRequest);
  }

  public registerFTW(authRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.authApiUrl}/users`, authRequest);
  }

  public onLogin(authResponse: AuthResponse): void {
    this.storageService.set(AppStorage.data, JSON.stringify(authResponse.data), true);
  }

  public checkEventIsBuyed(userCode: string): Observable<any> {
    return this.http.get<any>(`${this.authApiUrl}/feed/v1/stream_detail/${this.streamDetails[0].stream_guid}/${this.streamDetails[0].menu_guid}/${userCode}`);
  }

  public getEventStreamURL(): Observable<any> {
    return this.http.get<any>(`${this.authApiUrl}/feed/v1/get_stream_url/${this.streamDetails[0].stream_guid}/${this.streamDetails[0].menu_guid}`);
  }

  public onLogout(): void {
    this.storageService.remove(AppStorage.data, true);
    this.storageService.remove(AppStorage.isLiveEventBuyed, true);
    this.storageService.remove(AppStorage.streamDetails, true);
    this.storageService.remove(AppStorage.siteDomain, true);
    this.storageService.remove(AppStorage.siteTitle, true);
    this.storageService.remove(AppStorage.streamPlayUrl, true);
    this.storageService.remove(AppStorage.lastVisitedRoute, true);
    this.storageService.remove(AppStorage.chanallDetails, true);
    this.storageService.remove(AppStorage.clientDetails, true);
    this.storageService.remove(AppStorage.appDetails1, true);
    this.storageService.remove(AppStorage.appDetailsStorage, true);
    this.storageService.remove(AppStorage.appStripeData, true);
    this.storageService.remove(AppStorage.appCodeLS, true);
    this.storageService.remove(AppStorage.bundleDetails, true);
    this.storageService.remove(AppStorage.historyBundle, true);
    this.storageService.remove(AppStorage.swigitPurchased, true);
    this.storageService.remove(AppStorage.appBundleStripeData, true);
    
    // this.router.navigateByUrl('auth/login');
  }
}

export interface AuthRequest {
  email?: string;
  password?: string;
  appId?: string;
  postAction?: string;
}

export interface AuthResponse {
  status?: number;
  msg?: string;
  data?: UserData;
}

export interface UserData {
  name?: string;
  userCode?: string;
  username?: string;
  appId?: string;
  email?: string;
  accountStatus?: string;
  createdOn?: Date;
  updatedOn?: Date;
}