import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AuthComponent } from './auth.component';
import { LoginRouteComponent } from './login-route/login-route.component';
import { LogoutRouteComponent } from './logout-route/logout-route.component';
import { ResendcodeRouteComponent } from './resendcode-route/resendcode-route.component';
import { RegisterRouteComponent } from './register-route/register-route.component';
import { ChangePasswordRouteComponent } from './change-password-route/change-password-route.component';
import { ForgotPasswordRouteComponent } from './forgot-password-route/forgot-password-route.component';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './services/auth.service';
import { ErrorInterceptor } from '../shared/services/error.interceptor';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AuthComponent, 
    LoginRouteComponent, 
    LogoutRouteComponent, 
    ResendcodeRouteComponent, 
    RegisterRouteComponent, 
    ChangePasswordRouteComponent, 
    ForgotPasswordRouteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class AuthModule { }
