<button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
</button>
<div class="video-js-responsive-container vjs-hd">

    <!--div style="width: 93% !important; display: block; text-align: center; align-items: center;"-->

    <video id="example-video" class="video-js vjs-default-skin" crossorigin="true" controls>
    </video>
    
    
</div>
