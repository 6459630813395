import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { StorageService } from '../shared/services/storage.service';
import { APP_URLS } from '../providers/constant';
import { AppStorage } from '../providers/enum';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private authApiUrl = `${APP_URLS.WEB_API_URL}`;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  public createUser(authRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.authApiUrl}/users`, authRequest);
  }

  public accountActivation(activationObj: any): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.authApiUrl}/users`, activationObj);
  }

  public setNewsletterStatus(newsletterObj: any): Observable<NewsResponse> {
    return this.http.post<NewsResponse>(`${this.authApiUrl}/setnewsletterstatus`, newsletterObj);
  }
}

export interface AuthRequest {
  username?: string;
  name?: string;
  email?: string;
  appId?: string;
  postAction?: string;
  password?: string;
  confirmPassword?: string;
}

export interface AuthResponse {
  status?: number;
  msg?: string;
  data?: UserData;
}

export interface NewsResponse {
  status?: number;
  msg?: string;
}

export interface UserData {
  name?: string;
  userCode?: string;
  username?: string;
  appId?: string;
  email?: string;
  accountStatus?: string;
  createdOn?: Date;
  updatedOn?: Date;
}
