import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-event1',
  templateUrl: './event1.component.html',
  styleUrls: ['./event1.component.scss']
})
export class Event1Component implements OnInit {

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  } 

  public onCancel(): void {
    this.modalRef.hide();
  }
}
