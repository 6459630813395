import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { AppStorage } from '../providers/enum';
import { StorageService } from '../shared/services/storage.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  //#region variables

  @ViewChild('loginTemplate') public loginTemplate: ModalDirective;

  public errorMessage: string;
  public myProfileFormGroup: FormGroup;
  public userData: any;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private modalRef: BsModalRef,
    private storageService: StorageService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    this.createMyProfileFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private createMyProfileFormGroup(): void {
    this.myProfileFormGroup = this.formBuilder.group({
      name: this.userData.name,
      userName: this.userData.username,
      email: this.userData.email
    });
  }

  //#endregion

  //#region public methods

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onChangePasswordClick(): void {
    this.onCancel();
    this.modalRef = this.modalService.show(
      ChangePasswordComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }

  //#endregion

}
