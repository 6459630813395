<div class="container">

  <div class="loginRoute">
    <div class="card p-4 login">
      <div class="card-body" id="login" *ngIf="!isUserLogin">
        <h1 class="" style="font-size: 16px;">
          For security reasons, viewing of this event is limited to registered account holders.
          If you have an account, please Login or Create a Free Account. Once you have signed-in,
          you will be asked to enter your access code.<Br>
          Thank You. </h1>
        <br>
        <form [formGroup]="loginFormGroup" autocomplete="off">
          <div class="form-group">

            <label class="form-label mb-1 loginLabel" for="inputUserName">Email Address <span
                class="required-field">*</span></label>

            <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control" id="inputUserName"
              placeholder="Email Address" formControlName="email"
              [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.email.errors }">
            <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.email.errors) ||
                          (!!loginControls?.email.touched && !!loginControls?.email.invalid)">
              <ng-container class="pt-2" *ngIf="!!loginControls?.email.errors.required">
                {{ loginMessage.email.errors.required }}
              </ng-container>
            </small>
            <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.email.errors) ||
                          (!!loginControls?.email.touched && !!loginControls?.email.invalid)">
              <ng-container class="pt-2" *ngIf="loginControls?.email.errors.checkEmail">
                {{ loginMessage.email.errors.invalid }}
              </ng-container>
            </small>

          </div>
          <div class="form-group">

            <label class="form-label mb-1 loginLabel" for="inputPassword">Password <span
                class="required-field">*</span></label>
            <input nbInput fullWidth fieldSize="giant" type="password" class="form-control" id="inputPassword"
              placeholder="Password" autocomplete="new-password" formControlName="password"
              [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.password.errors }">
            <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.password.errors) ||
                          (!!loginControls?.password.touched && !!loginControls?.password.invalid)">
              <ng-container class="pt-2" *ngIf="!!loginControls?.password.errors.required">
                {{ loginMessage.password.errors.required }}
              </ng-container>
            </small>

          </div>
          <!--div id="activation-code" *ngIf="!isSignUp">
            <label class="form-label mb-1 loginLabel" for="inputActivationCode">Activation Code <span
                class="required-field">*</span></label>
            <input nbInput fullWidth fieldSize="giant" type="text" class="form-control" id="inputActivationCode"
              placeholder="Activation Code" formControlName="activationcode"
              [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.activationcode.errors }">
            <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.activationcode.errors) ||
                              (!!loginControls?.activationcode.touched && !!loginControls?.activationcode.invalid)">
              <ng-container class="pt-2" *ngIf="!!loginControls?.activationcode.errors.required">

              </ng-container>
            </small>


          </div--->

          <div class="text-center">
            <p id="error-message">
              {{ errorMessage }}
            </p>
          </div>
          <div *ngIf="showLoading" style="text-align: center;">
            <img src="../../../assets/loader.gif" width="50px">
          </div>
          <div id="login-box" style="padding-top: 10px;">
            <div class="d-flex justify-content-center">
              <button id="login-btn" class="btn px-4" (click)="onLoginClick()">Login</button>

              <!--button id="active-btn" class="btn px-4" *ngIf="!isSignUp"
                [disabled]="loginFormGroup.invalid || showLoading " (click)="onActiveClick()">Activate</button-->

              <!-- <button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button> -->
            </div>
          </div>

          <div class="row">
            <div class="" style="text-align: center; width: 100%;">
              <div (click)="onForgotPassword()" style="cursor:pointer;color: white; text-align: center;">
                Forgot password?
              </div>
            </div>
          </div>
          <br>
          <div class="text-center">
            <p style="color: #a8a7a7;">
              Don't have an account?
            </p>
          </div>
          <div class="text-center">
            <p class="createAccount" (click)="onCreateAccount()">
              Create Account
            </p>
          </div>
        </form>

      </div>

      <div class="text-center"  *ngIf="isUserLogin">
        <p id="error-message">
          {{ errorMessage }}
        </p>
      </div>

    </div>
  </div>
  
  
</div>
